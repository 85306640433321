import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

var Init = function(){
  var $window = $(window);
  var $header = $("header");
  var $news = $("#news");
  var $more = $("#more");
  var $moreContent = $("#more_content");
  var $moreButton = $("#more_btn");

  var _event = new JSONReader("#event_content", "event");
  _event.read("../mt_output/json/event-list.json", 3);

  var _news = new JSONReader("#news_content", "news");
  _news.read("../mt_output/json/news-list.json", 3);

  var CalendarInit = function(){
    var _swiper = new Swiper(".swiper", {
      spaceBetween: 0,
      slidesPerView: 1,
      loop: true,
      loopFillGroupWithBlank: true,
      autoHeight: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        waitForTransition: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      speed: 1000,
    });
  }

  /*var _calendar = new JSONReader(".swiper-wrapper", "calendar");
  _calendar.read("../mt_output/json/event-calendar.json", CalendarInit);*/

  var VisualInit = function(){
    var $mvBoxes = $(".mv-box");
    $($mvBoxes[0]).addClass("mv-box--open");
    var mvLength = $mvBoxes.length;
    var $mvIndicator = $(".mv-indicator");
    var $mvIndicatorN = $mvIndicator.find(".numerator");
    var $mvIndicatorD = $mvIndicator.find(".denominator").text(mvLength);
    var $mvIndicatorBar = $mvIndicator.find(".mv-indicator--bar span");
    var barTime = 25;
    var rotationTime = 8000;

    var VisualLoop = function(count){
      var _count = count;
      $mvIndicatorN.text(_count);
      $mvIndicatorBar.css({width: ""});

      _count %= mvLength;
      _count++;

      var _barPercentage = 0;
      var _barCount = 1;

      var _bar = setInterval(function(){
        _barCount++;
        _barPercentage = ((barTime * _barCount) / rotationTime) * 100;
        $mvIndicatorBar.css({width: _barPercentage + "%"});
      }, barTime);

      var _timer = setTimeout(
        function(){
          $mvBoxes.each(function(i){
            var _$this = $(this);
            _$this.removeClass("mv-box--open");
            if(i === _count - 1){
              _$this.addClass("mv-box--open");
            }
          });
          VisualLoop(_count);
          clearTimeout(_bar);
          clearTimeout(_timer);
        },
        rotationTime
      );
    };
    VisualLoop(1);
  };

  var _promo = new JSONReader("#mv_content", "promo");
  _promo.read("../mt_output/json/top-prom.json", 10, VisualInit);

  var MoreOpenClose = function(){
    $moreContent.stop().slideToggle(300, function(){
      if($moreContent.css("display") === "none"){
        $more.removeClass("more--open");
      }else{
        $more.addClass("more--open");
      }
    });
  };
  $moreButton.on("click", MoreOpenClose);

  var max = 4;
  var MoveElement = function(){
    var $newsBox = $("#news .news-box");
    if($newsBox.length > max){
      //console.log("MoveElement");
      $more.css({display: ""});
      $newsBox.each(function(i){
        if(i > max - 1){
          $(this).appendTo($moreContent);
        }
      });
    }else{
      $more.css({display: "none"});
    }
  };
  var BackElement = function(){
    var $newsBox = $("#more_content .news-box");
    if($newsBox.length > 0){
      //console.log("BackElement");
      $newsBox.each(function(i){
        $(this).appendTo($news);
      });
    }
  };

  var Resize = function(){
    //console.log("Resize");
    if($window.width() > 750){
      //BackElement();
    }else{
      //MoveElement();
    }
  };
  //$window.on("resize", Resize);
  //Resize();
};

window.addEventListener("load", Init);